import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../components/shared/page-with-cms-wrapper";

const Terms = ({
                 path,
                 data: {
                   strapiTerms: {
                     title,
                     content,
                     SeoTitle,
                     MetaDescriptions,
                   },
                 },
               }) => {

  return (
      <PageWithCmsWrapper
          path={path}
          title={title}
          content={content}
          seoProps={{ title: SeoTitle, description: MetaDescriptions}}
      />
  );
};

export default Terms;

export const query = graphql`
  query TermsQuery {
    strapiTerms {
      title
      content
      SeoTitle
      MetaDescriptions
    }
  }
`;
